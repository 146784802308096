import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(timesString: string ) {
    let times;
    times = timesString.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [timesString];
    if (times.length > 1) { // If times format correct
      times = times.slice (1);  // Remove full string match value
      times[5] = +times[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      times[3] = ' ';
      times[4] = ' ';
      times[0] = +times[0] % 12 || 12; // Adjust hours
    }
    return times.join ('');
  }

}
