import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  public cats:any=[];
  public subcats:any=[];
  constructor(
    private http: HttpClient,
    private router: Router,
    private popCtrl:PopoverController
  ) { }

  ngOnInit() {
    this.getCat();
  }
  getCat(){
    this.http.get('https://spartanbusiness.in/api/category.php').subscribe(data => {
      this.cats = data;
    });
  }
  goSub(c){
    const data = {
      id: c.id
    }
    this.http.post('https://spartanbusiness.in/api/subc.php', JSON.stringify(data))
  .subscribe((data) => {
   this.subcats = data;
  });
  }
  gocatloc(id,name){
    const params: NavigationExtras={
      queryParams:{
        catid:id,
        msname: 'Category'
      }
    }
    this.router.navigate(['catproducts'+'/'+id+'/'+name]);
    this.popCtrl.dismiss();
  }
}
