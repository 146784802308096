import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { NavParams, PopoverController, ToastController } from '@ionic/angular';
import { PostProvider } from 'src/app/pages/providers/post-provider';
declare var Razorpay:any;
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
})
export class StoreComponent implements OnInit {
  @ViewChild('demodate', {read: ElementRef}) demodate: ElementRef;
  @ViewChild('demotime', {read: ElementRef}) demotime: ElementRef;
  public product = this.navParmas.get('product');
  public demotyp = this.navParmas.get('demotyp');
  public demoprice = this.navParmas.get('demoprice');
  public democutp = this.navParmas.get('democutp');
  public pinfoid = this.navParmas.get('pinfoid');
  public user = this.navParmas.get('user');
  public store = this.navParmas.get('store');
  public users:any = [];
  uname: any;
  uemail: any;
  umobile: any;
  constructor(
    private navParmas: NavParams,
    private http: HttpClient,
    private toastCtrl: ToastController,
    private accs: PostProvider,
    private popc: PopoverController
  ) {
    const data = {
      id : localStorage.getItem('user')
    }
    this.http.post('https://spartanbusiness.in/api/userdetails.php', JSON.stringify(data)).subscribe((data)=>{
      this.users = data;
      for(let u of this.users){
        this.uname=u.name;
        this.uemail=u.email;
        this.umobile=u.mobile;
      }
      });
   }

  ngOnInit() {
  }
  goPdemo(tid){
    return new Promise(resolve=>{
                let body = {
                 product: this.product,
                 demotype:'Store Demo',
                 demoprice: +this.demoprice - +this.democutp,
                 prdinfo: this.pinfoid,
                 user:this.user,
                 store:this.store,
                 ddate:this.demodate.nativeElement.value,
                 dtime:this.demotime.nativeElement.value
                }
                console.log('Demo Body', body);
                this.accs.postData(body, 'addstoredemocart.php').subscribe((res:any)=>{
                  if(res.success == true){
                    let walb = {
                      action: 'add_wall',
                      demoprice: +this.demoprice - +this.democutp,
                      user:this.user,
                      demotype:'Store Demo',
                      tid:tid
                     }
                     this.accs.postData(walb, 'addwalletprice.php').subscribe((res:any)=>{
                      if(res.success == true){
                        this.popc.dismiss();
                        this.accs.postData(walb, 'addwallettransaction.php').subscribe((res:any)=>{
                          if(res.success == true){
                            this.presentToast('Successfully Added Amount to Wallet','success');
                          }
                        });
                      }else{
                        this.presentToast('Something Went Wrong','danger');
                      }
                    });
                    
                  }else{
                    this.presentToast('Already Requested for Home Demo','danger');
                  }
              });
              });
  }
  goDemo(){
    const tid = this.getRandomString();
    var options = {
      description: 'Checkout',
      image: 'http://spartanbusiness.in/assets/images/logo.png',
      currency: 'INR', // your 3 letter currency code
      key: "rzp_live_LLouAcrdFUWKP7", // your Key Id from Razorpay dashboard
      amount: this.demoprice*100, // Payment amount in smallest denomiation e.g. cents for USD
      name: 'Spartan Business',
      //order_id: "order_9A33XWu170gUtm",
      handler: function (response){
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature)
    },
      prefill: {
        
        email: this.uemail,
        contact: this.umobile,
        name: this.uname
      },
      theme: {
        color: '#528FF0'
      },
      notes: {
        //ondismiss: function () {
        //  alert('dismissed')
        //}
      }
    };
    var rzp1 = new Razorpay(options);
    //var successCallback = function(payment_id) {
    //  alert('payment_id: ' + payment_id);
    //  this.confirmpend(paytyp,orid);
    //};
    //var cancelCallback = function (error) {
    //  alert(error.description + ' (Error ' + error.code + ')');
    //};
    rzp1.on('payment.failed', function (response){
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.on('payment.success', function (response){
      this.goPdemo(tid);
    });
    rzp1.open();
}
getRandomString() {
  const len = 10;
  const arr = '1234567890asdfghjklqwertyuiopzxcvbnmASDFGHJKLQWERTYUIOPZXCVBNM';
  let ans = '';
  for (let i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)];
  }
  return ans;
}
paymentSuccess(orderId: string, totalPrice: number, paymentMethod: string) {
  alert(`Payment successful Order Id ${orderId} payment method ${paymentMethod} of amount ${totalPrice}`);
}
async presentToast(a,c){
  let toast = this.toastCtrl.create({
    message: a,
    duration: 3000,
    position: 'top',
    color:c
  });

 (await toast).present();
} 
}
