import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public sp = this.navparam.get('sp');
  public se = this.navparam.get('se');
  constructor(
    private navparam: NavParams,
  ) { }

  ngOnInit() {}

}
