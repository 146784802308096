import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { NavParams, PopoverController, ToastController } from '@ionic/angular';
import { PostProvider } from '../providers/post-provider';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss'],
})
export class AmountComponent implements OnInit {
  @ViewChild('amount', {read: ElementRef}) value: ElementRef;
  userid: any;
amount:any;

  constructor(
    private navParams: NavParams,
    private webIntent: WebIntent,
    private toastCtrl: ToastController,
    private accs: PostProvider,
    private pov: PopoverController
  ) { }
 
  ngOnInit() {
    console.log(this.navParams.data.userid);
    this.userid = this.navParams.data.userid
  }
  addamount(tid){
    return new Promise(resolve=>{
           let walb = {
            demoprice: this.value.nativeElement.value,
            user:localStorage.getItem('user'),
            demotype:'Added Amount',
            tid:tid
           }
           console.log(walb);
           this.accs.postData(walb, 'addwalletprice.php').subscribe((res:any)=>{
            if(res.success == true){
              this.accs.postData(walb, 'addwallettransaction.php').subscribe((res:any)=>{
                if(res.success == true){
                  this.pov.dismiss();
                  this.presentToast('Successfully Added Amount to Wallet','success');
                }
              });
            }else{
              this.presentToast('Something Went Wrong','danger');
            }
          });
          
        });
   
  }
  addMoney(id){
    const tid = this.getRandomString();
      const orderId = this.getRandomString();
      const totalPrice = this.value.nativeElement.value;
      const UPI_ID = 'SPARTANBUSINESS@SBI';
      const UPI_NAME = 'SpartanBusiness';
      const UPI_TXN_NOTE = 'Added Amount';
      // tslint:disable-next-line: max-line-length
      let uri = `upi://pay?pa=${UPI_ID}&pn=${UPI_NAME}&tid=${tid}&am=${totalPrice}&cu=INR&tn=${UPI_TXN_NOTE}&tr=${orderId}`;
      uri = uri.replace(' ', '+');
      (window as any).plugins.intentShim.startActivityForResult(
        {
          action: this.webIntent.ACTION_VIEW,
          url: uri,
          requestCode: 1
        }, intent => {
          if (intent.extras.requestCode === 1 &&
              intent.extras.resultCode === (window as any).plugins.intentShim.RESULT_OK &&
              intent.extras.Status &&
              (((intent.extras.Status as string).toLowerCase()) === ('success'))) {
            this.addamount(tid);
          } else {
            alert('payment failed');
          }
        }, err => {
          alert('error ' + err);
        });
      
  }
  getRandomString() {
    const len = 10;
    const arr = '1234567890asdfghjklqwertyuiopzxcvbnmASDFGHJKLQWERTYUIOPZXCVBNM';
    let ans = '';
    for (let i = len; i > 0; i--) {
        ans += arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }
  paymentSuccess(orderId: string, totalPrice: number, id: any, paymentMethod: string) {
    return new Promise(resolve=>{
      let body = {
       orderid: orderId,
       totalprice: totalPrice,
       id: id
      }
      this.accs.postData(body, 'addmoney.php').subscribe((res:any)=>{
        if(res.success == true){
          this.presentToast('Amount added to Wallet','danger');
          //this.storage.set('storage_session', body); //create storage session
          //this.navCtrl.navigateForward(['/login']);
          console.log(body);
        }else{
          this.presentToast('Something! Went Wrong', 'danger');
        }
    });
    });
  }
  async presentToast(a,c) {
    const toast = await this.toastCtrl.create({
      message: a,
      duration: 1500,
      color: c
    });
   await toast.present();
  }
}
