import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { ModalController, NavParams, PopoverController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { PostProvider } from 'src/app/pages/providers/post-provider';

@Component({
  selector: 'app-wish',
  templateUrl: './wish.component.html',
  styleUrls: ['./wish.component.scss'],
})
export class WishComponent implements OnInit {
  @ViewChild('amount', {read: ElementRef}) amount: ElementRef;

  public productid = this.navParmas.get('productid');
  public wishid = this.navParmas.get('wishid');
  public productinfo = this.navParmas.get('productinfo');
  public price = this.navParmas.get('price');
  public user:any = [];
  constructor(
    private pov:PopoverController,
    private http: HttpClient,
    private accs: PostProvider,
    private storage: Storage,
    private toastCtrl: ToastController,
    private webIntent: WebIntent,
    private navParmas: NavParams
  ) { }

  ngOnInit() {
    this.storage.get('storage_session').then((res)=>{
      this.user = res;
    });
  }
  goWish(tid){
    return new Promise(resolve=>{
      let body = {
       ramount : this.amount.nativeElement.value,
       wish : this.wishid,
       product : this.productid,
       user : localStorage.getItem('user'),
       productinfo : this.productinfo,
       transid : tid,
       totalp : this.price
       
      }
      this.accs.postData(body, 'updatewishrequest.php').subscribe((res:any)=>{
        if(res.success == true){ 
          let walb = {
            action: 'add_wall',
            demoprice: this.price,
            user:localStorage.getItem('user'),
            demotype:'Wish Deal',
            tid:tid
           }
           this.accs.postData(walb, 'addwalletprice.php').subscribe((res:any)=>{
            if(res.success == true){
              this.accs.postData(walb, 'addwallettransaction.php').subscribe((res:any)=>{
                if(res.success == true){
                  this.presentToast('Successfully Added Amount to Wallet','success');
                }
              });
              this.presentToast('Wish Request send Successfully','');
          this.pov.dismiss();
             
            }else{
              this.presentToast('Something Went Wrong','danger');
            }
          });
          
        }
        else{
          alert('payment failed');
        }
      });
    });
  }
  goOrder(){
      return new Promise(resolve=>{
        let body = {
         ramount : this.amount.nativeElement.value,
         wish : this.wishid,
         product : this.productid,
         user : localStorage.getItem('user'),
         productinfo : this.productinfo
         
        }
        console.log(body);
        this.accs.postData(body, 'wishrequest.php').subscribe((res:any)=>{
          if(res.success == true){
           
            this.presentToast('Make Payment for WishPrice','');
            const tid = this.getRandomString();
            const orderId = this.getRandomString();
            const totalPrice = this.price;
            const UPI_ID = 'SPARTANBUSINESS@SBI';
            const UPI_NAME = 'SPARTANBUSINESS';
            const UPI_TXN_NOTE = 'Wish Price';
            // tslint:disable-next-line: max-line-length
            let uri = `upi://pay?pa=${UPI_ID}&pn=${UPI_NAME}&tid=${tid}&am=${totalPrice}&cu=INR&tn=${UPI_TXN_NOTE}&tr=${orderId}`;
            uri = uri.replace(' ', '+');
            (window as any).plugins.intentShim.startActivityForResult(
              {
                action: this.webIntent.ACTION_VIEW,
                url: uri,
                requestCode: 1
              }, intent => {
                if (intent.extras.requestCode === 1 &&
                    intent.extras.resultCode === (window as any).plugins.intentShim.RESULT_OK &&
                    intent.extras.Status &&
                   (((intent.extras.Status as string).toLowerCase()) === ('success'))) {
                  this.paymentSuccess('orderId', 'UPI');
                  this.goWish(tid);
               } else {
                  alert('payment failed');
                }
              }, err => {
                alert('error ' + err);
              });
          }else{
            this.pov.dismiss();
            this.presentToast('Product already in WishList','');
          }
      });
      });
    
   
  }
  getRandomString() {
    const len = 10;
    const arr = '1234567890asdfghjklqwertyuiopzxcvbnmASDFGHJKLQWERTYUIOPZXCVBNM';
    let ans = '';
    for (let i = len; i > 0; i--) {
        ans += arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }
  paymentSuccess(orderId: string, paymentMethod: string) {
    alert(`Payment successful Order Id ${orderId} payment method ${paymentMethod}`);
  }
  close(){
    this.pov.dismiss();
  }
  async presentToast(a,c){
    const toast = await this.toastCtrl.create({
      message: a,
      position: 'bottom',
      duration: 3000,
      color: c
    });
    return toast.present();
  }

}
