// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyCP_2ONIXbO_Cq-NXgbtLQJvxiMVVolTDk",
  authDomain: "spartanbusiness-c4523.firebaseapp.com",
  projectId: "spartanbusiness-c4523",
  storageBucket: "spartanbusiness-c4523.appspot.com",
  messagingSenderId: "240336705540",
  appId: "1:240336705540:web:0d0e39d8fc611f33b57d51",
  measurementId: "G-FNNYM8H8ZY"
  },
  onesignal: {
    appId: '6278e1b3-9043-4bc6-8670-5a71dfd3d0c2',
    googleProjectNumber: '240336705540',
    restKey: 'MzdiYWFkNjktNTljMC00ZjVmLTkwOWMtZDExZGE0MTM0ODBk'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
