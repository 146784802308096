import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, PopoverController } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule } from '@angular/common/http';
import { PostProvider } from './pages/providers/post-provider';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { FirebaseAuthentication } from '@awesome-cordova-plugins/firebase-authentication/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { CommonModule } from '@angular/common';
import { DemoComponent } from './components/demo/demo.component';
import { WishComponent } from './popc/wish/wish.component';
import { WaitlistComponent } from './popc/waitlist/waitlist.component';
import { MapsearchComponent } from './components/mapsearch/mapsearch.component';
import { ServicecallComponent } from './components/servicecall/servicecall.component';
import { StoreComponent } from './components/store/store.component';
import { TimePipe } from './pipes/time.pipe';
import { FiltoverComponent } from './components/filtover/filtover.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { AmountComponent } from './pages/amount/amount.component';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { CartdComponent } from './components/cartd/cartd.component';
import { HeaderComponent } from './components/header/header.component';
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { ContactComponent } from './components/contact/contact.component';
import { FinanceComponent } from './components/finance/finance.component';
@NgModule({
  declarations: [AppComponent, WaitlistComponent, MapsearchComponent, TimePipe, FiltoverComponent, CategoriesComponent,DemoComponent,StoreComponent,ServicecallComponent,CartdComponent,HeaderComponent,ContactComponent,FinanceComponent],
  entryComponents: [WaitlistComponent, MapsearchComponent, FiltoverComponent, CategoriesComponent,DemoComponent,StoreComponent,ServicecallComponent,CartdComponent,HeaderComponent,ContactComponent,FinanceComponent],
  imports: [
    BrowserModule,
    CommonModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    PostProvider,
    Geolocation,
    NativeGeocoder,
    SocialSharing,
    FirebaseAuthentication,
    PopoverController,
    DemoComponent,
    WishComponent,
    WaitlistComponent,
    MapsearchComponent,
    ServicecallComponent,
    StoreComponent,
    AmountComponent,
    OneSignal,
    AndroidPermissions,
    BarcodeScanner,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    WebIntent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
