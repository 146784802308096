import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { FiltoverComponent } from 'src/app/components/filtover/filtover.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { PostProvider } from '../providers/post-provider';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-locproducts',
  templateUrl: './locproducts.page.html',
  styleUrls: ['./locproducts.page.scss'],
})
export class LocproductsPage implements OnInit {
  public cats:any = [];
  public admin:any = [];
  phone: any;
  sphone: any;
  semail: any;
  lid:any;
  public wdeals:any = [];
  public fp:any = [];
  public tp:any = [];
  public bs:any = [];
  public deals:any = [];
  public carttotal:any = [];

  displayMode:number;
  public prds:any = [];
  public locbanner:any = [];
  public locaddrs:any = [];
  public user:any = [];
  public locs:any = [];
  userid: any;
  popularity=false;
  filtertype: any;
  quantity: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private actR: ActivatedRoute,
    private storage: Storage,
    private accs: PostProvider,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    public popoverCtrl: PopoverController
  ) {
    this.actR.params.subscribe((data)=>{
      this.lid = data.id;
      this.getCat();
      this.getWishdeals();
      this.getDailydeals();
    });
    this.user=localStorage.getItem('user');
   }
   getCat(){
    this.http.get('https://spartanbusiness.in/api/category.php').pipe(map(res => res)).subscribe(data => {
    this.cats = data;
    });
    this.http.get('https://spartanbusiness.in/api/admin.php').pipe(map(res => res)).subscribe(data => {
    this.admin = data;
    for(let a of this.admin){
      this.phone= a.phone;
      this.sphone=a.support_phones;
      this.semail=a.support_email;
    }
    });
    }
    getWishdeals(){
      this.http.get('https://spartanbusiness.in/api/wishdeal.php').pipe(map(res => res)).subscribe(data => {
        this.wdeals = data;
        });
        this.http.get('https://spartanbusiness.in/api/fp.php').pipe(map(res => res)).subscribe(data => {
        this.fp = data;
        });
        this.http.get('https://spartanbusiness.in/api/tp.php').pipe(map(res => res)).subscribe(data => {
        this.tp = data;
        });
        this.http.get('https://spartanbusiness.in/api/bs.php').pipe(map(res => res)).subscribe(data => {
        this.bs = data;
        });
    }
    getDailydeals(){
      this.http.get('https://spartanbusiness.in/api/dailydeals.php').pipe(map(res => res)).subscribe(data => {
        this.deals = data;
        });
    }
  ngOnInit() {
    console.log('location', this.lid);
        const data = {
          id: this.user
        }
        this.http.post('https://spartanbusiness.in/api/allfavourite.php', JSON.stringify(data)).subscribe((data)=>{
          this.locs = data;
          console.log(this.locs);
         }); 
         this.http.post('https://spartanbusiness.in/api/getcarttotal.php', JSON.stringify(data)).subscribe((data)=>{
    this.carttotal = data;
    for(let c of this.carttotal){
      this.quantity = c.quantity;
    }
    });
    
    const somed = {
      lid: this.lid
    }
    console.log(this.lid);
    this.http.post('https://spartanbusiness.in/api/locprds.php', JSON.stringify(somed)).subscribe((data)=>{
    this.prds = data;
    });
    const locb = {
      bid: this.lid
    }
    this.http.post('https://spartanbusiness.in/api/locban.php', JSON.stringify(locb)).subscribe((data)=>{
    this.locbanner = data;
    });
    const favd = {
      lid: this.lid,
    }
    this.http.post('https://spartanbusiness.in/api/locaddrs.php', JSON.stringify(favd)).subscribe((data)=>{
      this.locaddrs = data;
      console.log('Favourate Locations', this.locaddrs)
      });
    }
  
  onDisplayModeChange(mode: number): void {
    this.displayMode = mode;
  }
  prdDtl(id,name){
    this.Close();
    this.router.navigate(['/productdetails/'+id+'/'+name]);
  }
  Close(){
    this.modalCtrl.dismiss();
  }
  addF(id,user){
    return new Promise(resolve=>{
      let body = {
       id: id,
       user: user,
      }
      this.accs.postData(body, 'favourite.php').subscribe((res:any)=>{
        if(res.success == true){
          this.presentToast('Added to Favourite');
          
          console.log(body);
        }else{
          this.presentToast('Already added to Favourite Locations');
        }
    });
    });
  }
  addWishlist(id, infoid, mrp, sellprc){
    return new Promise(resolve=>{
      let body = {
       id: id,
       infoid: infoid,
       mrp: mrp,
       sellprc: sellprc,
       uid: this.user
      }
      this.accs.postData(body, 'wishlist.php').subscribe((res:any)=>{
        if(res.success == true){
          this.ngOnInit();
         this.presentToast('Product added to Wishlist');
         
        }else{
          
        }
    });
    });
  }
  goC(pid,uid,pfid,sellprice,mrprice,shipprice){
    return new Promise(resolve=>{
      let body = {
       product: pid,
       user: this.user,
       productinfo: pfid,
       itemcost: sellprice,
       itemmrp: mrprice,
       shippingcharge: shipprice
      }
      console.log(body);
      this.accs.postData(body, 'addtocart.php').subscribe((res:any)=>{
        if(res.success == true){
          this.presentToast('Product Added to Cart');
          
        }else{
          this.presentToast('Product already in cart');
          console.log(res.msg);
        }
    });
    });
  } 
  async presentToast(a) {
    const toast = await this.toastCtrl.create({
      message: a,
      duration: 1500,
      color: 'primary',
      position: 'middle'
    });
   await toast.present();
  }
  async poplar(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: PopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      
    });
    await popover.present();

    await popover.onDidDismiss().then((data)=>{
      console.log(data);
      if(data && data.data && data.role === 'filterType'){
        this.filtertype = data.data.prdpopu;
        console.log('Filter Type',this.filtertype);
        this.popularity=true;
        if(this.filtertype=='lowtohigh'){
          this.getPltohDeals();
        }
        if(this.filtertype=='hightolow'){
          this.getPhtolDeals();
        }
        if(this.filtertype=='newproducts'){
          this.getPlprdDeals();
        }
        if(this.filtertype=='bestprice'){
          this.getPbsDeals();
        }
      }
  }
    )};
    getPltohDeals(){
      const somed = {
        lid: this.lid
      }
      console.log(this.lid);
      this.http.post('https://spartanbusiness.in/api/ltohlocprds.php', JSON.stringify(somed)).subscribe((data)=>{
      this.prds = data;
      });
    }
    getPhtolDeals(){
      const somed = {
        lid: this.lid
      }
      console.log(this.lid);
      this.http.post('https://spartanbusiness.in/api/htollocprds.php', JSON.stringify(somed)).subscribe((data)=>{
      this.prds = data;
      });
    }
    getPlprdDeals(){
      const somed = {
        lid: this.lid
      }
      console.log(this.lid);
      this.http.post('https://spartanbusiness.in/api/lprdlocprds.php', JSON.stringify(somed)).subscribe((data)=>{
      this.prds = data;
      });
    }
    getPbsDeals(){
      const somed = {
        lid: this.lid
      }
      console.log(this.lid);
      this.http.post('https://spartanbusiness.in/api/ltohlocprds.php', JSON.stringify(somed)).subscribe((data)=>{
      this.prds = data;
      });
    }

  
  async filter(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: FiltoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
  goHome(){
    this.router.navigate(['home']);
  }
  goWish(id){
    this.router.navigate(['/wishlist/'+id]);
  }
  goProfile(id){
    this.router.navigate(['/profile/'+id]);
  }
  location(){
    this.router.navigate(['/map']);
  }
  goCart(id){
    this.router.navigate(['/cart/'+id]);
  }
  gopD(name){
    this.router.navigate(['/deals/'+name]);
    }
  goCon(){

    }
  goP(id,name){
      this.router.navigate(['/catproducts/'+id+'/'+name]);
    }
    logOut(){
      localStorage.clear();
      this.storage.clear().then(async ()=>{
        this.router.navigate(['/login']);
        const toast = await this.toastCtrl.create({
            message: 'logout succesful',
            duration: 3000
          });
        toast.present();
      });
    }
}
