import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { NavParams, PopoverController, ToastController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { PostProvider } from 'src/app/pages/providers/post-provider';
declare var Razorpay:any;
@Component({
  selector: 'app-servicecall',
  templateUrl: './servicecall.component.html',
  styleUrls: ['./servicecall.component.scss'],
})
export class ServicecallComponent implements OnInit {
  @ViewChild('productname', {read: ElementRef}) value: ElementRef;
  @ViewChild('namebrand', {read: ElementRef}) namebrand: ElementRef;
  @ViewChild('modelname', {read: ElementRef}) modelname: ElementRef;
  @ViewChild('working', {read: ElementRef}) working: ElementRef;
  @ViewChild('warranty', {read: ElementRef}) warranty: ElementRef;
  @ViewChild('sdate', {read: ElementRef}) sdate: ElementRef;
  @ViewChild('stime', {read: ElementRef}) stime: ElementRef;
  public product = this.navparam.get('product');
  public demotyp = this.navparam.get('demotyp');
  public demoprice = this.navparam.get('demoprice');
  public democutp = this.navparam.get('democutp');
  public catname = this.navparam.get('catname');
  public catid = this.navparam.get('catid');
  public brandname = this.navparam.get('brandname');
  public brandid = this.navparam.get('brandid');
  public prdnam = this.navparam.get('prdnam');
  public modname = this.navparam.get('modname');
  public user = this.navparam.get('user');
  public users:any = [];
  uname: any;
  uemail: any;
  umobile: any;
  inwarranty:any;
  constructor(
    private navparam: NavParams,
    private http: HttpClient,
    private popC: PopoverController,
    private accs: PostProvider,
    private toastCtrl: ToastController,
    private webIntent: WebIntent
  ) { 
    const data = {
      id : localStorage.getItem('user')
    }
    this.http.post('https://spartanbusiness.in/api/userdetails.php', JSON.stringify(data)).subscribe((data)=>{
      this.users = data;
      for(let u of this.users){
        this.uname=u.name;
        this.uemail=u.email;
        this.umobile=u.mobile;
      }
      });
  }

  ngOnInit() {
    
  }
Cancel(){
  this.popC.dismiss();
}
goServiceCall(tid){
  return new Promise(resolve=>{
    let body = {
     productname: this.value.nativeElement.value,
     namebrand: this.namebrand.nativeElement.value,
     modelname: this.modelname.nativeElement.value,
     working: this.working.nativeElement.value,
     warranty: this.warranty.nativeElement.value,
     sdate: this.sdate.nativeElement.value,
     stime: this.stime.nativeElement.value,
     user: this.user,
     sprice: this.demoprice
    }
    console.log('Service Body', body);
    this.accs.postData(body, 'addservicecart.php').subscribe((res:any)=>{
      if(res.success == true){
         let walb = {
          action: 'add_wall',
          demoprice: +this.demoprice - +this.democutp,
          user:this.user,
          demotype:'Service Call',
          tid:tid
         }
         this.accs.postData(walb, 'addwalletprice.php').subscribe((res:any)=>{
          if(res.success == true){
            this.accs.postData(walb, 'addwallettransaction.php').subscribe((res:any)=>{
              if(res.success == true){
                this.presentToast('Successfully Added Amount to Wallet','success');
              }
            });
            this.popC.dismiss();
          }else{
            this.presentToast('Something Went Wrong','danger');
          }
        });
        
      }else{
        this.presentToast('Already Requested for Service Call','danger');
      }
  });
  });
}
placeOrd(){
  const tid = this.getRandomString();
  var options = {
    description: 'Checkout',
    image: 'http://spartanbusiness.in/assets/images/logo.png',
    currency: 'INR', // your 3 letter currency code
    key: "rzp_live_LLouAcrdFUWKP7", // your Key Id from Razorpay dashboard
    amount: this.demoprice*100, // Payment amount in smallest denomiation e.g. cents for USD
    name: 'Spartan Business',
    //order_id: "order_9A33XWu170gUtm",
    handler: function (response){
      alert(response.razorpay_payment_id);
      alert(response.razorpay_order_id);
      alert(response.razorpay_signature)
  },
    prefill: {
      
      email: this.uemail,
      contact: this.umobile,
      name: this.uname
    },
    theme: {
      color: '#528FF0'
    },
    notes: {
      //ondismiss: function () {
      //  alert('dismissed')
      //}
    }
  };
  var rzp1 = new Razorpay(options);
  //var successCallback = function(payment_id) {
  //  alert('payment_id: ' + payment_id);
  //  this.confirmpend(paytyp,orid);
  //};
  //var cancelCallback = function (error) {
  //  alert(error.description + ' (Error ' + error.code + ')');
  //};
  rzp1.on('payment.failed', function (response){
    alert(response.error.code);
    alert(response.error.description);
    alert(response.error.source);
    alert(response.error.step);
    alert(response.error.reason);
    alert(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);
  });
  rzp1.on('payment.success', function (response){
    this.goServiceCall(tid);
  });
  rzp1.open(); 
}
getRandomString() {
  const len = 10;
  const arr = '1234567890asdfghjklqwertyuiopzxcvbnmASDFGHJKLQWERTYUIOPZXCVBNM';
  let ans = '';
  for (let i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)];
  }
  return ans;
}
paymentSuccess(orderId: string, totalPrice: number, paymentMethod: string) {
  alert(`Payment successful Order Id ${orderId} payment method ${paymentMethod} of amount ${totalPrice}`);
}
async presentToast(a,c){
  let toast = this.toastCtrl.create({
    message: a,
    duration: 3000,
    position: 'top',
    color:c
  });

 (await toast).present();
} 
}
