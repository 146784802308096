import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map'; 

@Injectable()
export class PostProvider {
	server: string = 'https://spartanbusiness.in/api/'; 
    constructor(public http : HttpClient) {

	}

	postData(body, file){
		let type = "application/json; charset=UTF-8";
		let headers = new HttpHeaders({ 'Content-Type': type });
		let options = { headers: headers };

		return this.http.post(this.server + file, JSON.stringify(body), options)
        .map(res => res);
	}
postImageData(url, data){
	let header = new HttpHeaders();
    header = header.set("Accept", "application/json");
    return this.http.post(this.server + url, data, { headers: header });
}
}

