import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { LocproductsPage } from 'src/app/pages/locproducts/locproducts.page';
import { CategoriesComponent } from '../categories/categories.component';

@Component({
  selector: 'app-mapsearch',
  templateUrl: './mapsearch.component.html',
  styleUrls: ['./mapsearch.component.scss'],
})
export class MapsearchComponent implements OnInit {
  public cats:any=[];
  public brands:any=[];
  public mpD:any=[];
  constructor(
    private http: HttpClient,
    private router: Router,
    private popCtrl: PopoverController,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.getCat();
    this.getBrands();
    this.mapdata();
  }
  mapdata(){
    this.http.get('https://spartanbusiness.in/api/loc_address.php').pipe(map(res => res)).subscribe(data => {
      this.mpD = data;
      });
     // this.displayGoogleMap();
     // this.getMarkers();
     
  }
  getCat(){
    this.http.get('https://spartanbusiness.in/api/category.php')
    .pipe(map(res => res)).subscribe(data => {
      this.cats = data;
    });
  }
  getBrands(){
    this.http.get('https://spartanbusiness.in/api/brands.php').pipe(map(res => res)).subscribe(data => {
      this.brands = data;
      });
  }
  gocatloc(eve){
    const params: NavigationExtras={
      queryParams:{
        catid:eve.detail.value,
        msname: 'Category'
      }
    }
    this.router.navigate(['/tabs/map'],params);
    this.popCtrl.dismiss();
    console.log(eve);
  }
  gobrdloc(eve){
    const params: NavigationExtras={
      queryParams:{
        catid:eve.detail.value,
        msname: 'Brand'
      }
    }
    this.router.navigate(['/tabs/map'],params);
    this.popCtrl.dismiss();
  }
  async gopinloc(eve){
    const modal = await this.modalCtrl.create({
      component: LocproductsPage,
      componentProps: {
        'lid': eve.detail.value
      }
    });
    await modal.present();
    this.popCtrl.dismiss();
  }
  async goCats(){
    this.popCtrl.dismiss();
    const popover = await this.popCtrl.create({
      component: CategoriesComponent,
      cssClass: 'catlocsearch',
      mode: 'ios'
      
    });
    await popover.present();
  }
}
