import { Component, OnInit } from '@angular/core';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { ModalController, NavParams, PopoverController, ToastController } from '@ionic/angular';
import { PostProvider } from 'src/app/pages/providers/post-provider';

@Component({
  selector: 'app-waitlist',
  templateUrl: './waitlist.component.html',
  styleUrls: ['./waitlist.component.scss'],
})
export class WaitlistComponent implements OnInit {
  public eamount = this.navParmas.get('earnestamount');
  public productid = this.navParmas.get('productid');
  public user = this.navParmas.get('user');
  public productinfo = this.navParmas.get('productinfo');
  public sellprice = this.navParmas.get('sellprice');
  public shipprice = this.navParmas.get('shipprice');
  public mrp = this.navParmas.get('mrp');
  constructor(
    private pov: PopoverController,
    private webIntent: WebIntent,
    private navParmas: NavParams,
    private accs: PostProvider,
    private toastCtrl: ToastController
  ) { }

 
  ngOnInit() {
  }
Close(){
  this.pov.dismiss();
}
dpO(tid){
  return new Promise(resolve=>{
    let body = {
     id: this.productid,
     infoid: this.productinfo,
     uid: localStorage.getItem('user'),
     sellprc: this.sellprice,
      mrp: this.mrp
    }
    console.log(body);
    this.accs.postData(body, 'dwishlist.php').subscribe((res:any)=>{
      if(res.success == true){
       let walb = {
        action: 'add_wall',
        demoprice: this.eamount,
        user:localStorage.getItem('user'),
        demotype:'Wait List',
        tid:tid
       }
       this.accs.postData(walb, 'addwalletprice.php').subscribe((res:any)=>{
        if(res.success == true){
          this.presentToast('Product added to Wait List');
          this.pov.dismiss();
          this.accs.postData(walb, 'addwallettransaction.php').subscribe((res:any)=>{
            if(res.success == true){
              
                  this.presentToast('Successfully Added Amount to Wallet');
                
            }
          });
        }else{
          this.presentToast('Something Went Wrong');
        }
      });
      }else{
        this.presentToast('Something Wrong');
      }
  });
  });
}
pO(){
  const tid = this.getRandomString();
    const orderId = this.getRandomString();
    const totalPrice = this.eamount;
    const UPI_ID = 'SPARTANBUSINESS@SBI';
    const UPI_NAME = 'SPARTANBUSINESS';
    const UPI_TXN_NOTE = 'WaitList';
    // tslint:disable-next-line: max-line-length
    let uri = `upi://pay?pa=${UPI_ID}&pn=${UPI_NAME}&tid=${tid}&am=${totalPrice}&cu=INR&tn=${UPI_TXN_NOTE}&tr=${orderId}`;
    uri = uri.replace(' ', '+');
    (window as any).plugins.intentShim.startActivityForResult(
      {
        action: this.webIntent.ACTION_VIEW,
        url: uri,
        requestCode: 1
      }, intent => {
        if (intent.extras.requestCode === 1 &&
            intent.extras.resultCode === (window as any).plugins.intentShim.RESULT_OK &&
            intent.extras.Status &&
            (((intent.extras.Status as string).toLowerCase()) === ('success'))) {
          this.paymentSuccess(orderId, 'UPI');
          this.dpO(tid);
        } else {
          alert('payment failed');
        }
      }, err => {
        alert('error ' + err);
      });
}
getRandomString() {
  const len = 10;
  const arr = '1234567890asdfghjklqwertyuiopzxcvbnmASDFGHJKLQWERTYUIOPZXCVBNM';
  let ans = '';
  for (let i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)];
  }
  return ans;
}

paymentSuccess(orderId: string, paymentMethod: string) {
  alert(`Payment successful Order Id ${orderId} payment method ${paymentMethod}`);
}
async presentToast(a){
  let toast = this.toastCtrl.create({
    message: a,
    duration: 3000,
    position: 'top'
  });

 (await toast).present();
} 
}
