import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'addaddress',
    loadChildren: () => import('./pages/addaddress/addaddress.module').then( m => m.AddaddressPageModule)
  },
  {
    path: 'address/:id',
    loadChildren: () => import('./pages/address/address.module').then( m => m.AddressPageModule)
  },
  {
    path: 'cart/:id',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'checkout/:id',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'orderconfirm',
    loadChildren: () => import('./pages/orderconfirm/orderconfirm.module').then( m => m.OrderconfirmPageModule)
  },
  {
    path: 'pcdetails',
    loadChildren: () => import('./pages/pcdetails/pcdetails.module').then( m => m.PcdetailsPageModule)
  },
  {
    path: 'productdetails/:id/:name',
    loadChildren: () => import('./pages/productdetails/productdetails.module').then( m => m.ProductdetailsPageModule)
  },
  {
    path: 'products/:id/:name',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'subcategories/:id/:name',
    loadChildren: () => import('./pages/subcategories/subcategories.module').then( m => m.SubcategoriesPageModule)
  },
  {
    path: 'subsubcategories/:id/:name',
    loadChildren: () => import('./pages/subsubcategories/subsubcategories.module').then( m => m.SubsubcategoriesPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'deals/:name',
    loadChildren: () => import('./pages/deals/deals.module').then( m => m.DealsPageModule)
  },
  {
    path: 'locproducts/:id',
    loadChildren: () => import('./pages/locproducts/locproducts.module').then( m => m.LocproductsPageModule)
  },
  {
    path: 'ratemap/:id/:user',
    loadChildren: () => import('./pages/ratemap/ratemap.module').then( m => m.RatemapPageModule)
  },
  {
    path: 'wishlist/:id',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'guestlogin',
    loadChildren: () => import('./pages/guestlogin/guestlogin.module').then( m => m.GuestloginPageModule)
  },
  {
    path: 'orders/:id',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'editprofile',
    loadChildren: () => import('./pages/editprofile/editprofile.module').then( m => m.EditprofilePageModule)
  },
  {
    path: 'manageaddress/:id',
    loadChildren: () => import('./pages/manageaddress/manageaddress.module').then( m => m.ManageaddressPageModule)
  },
  {
    path: 'newaddress/:id',
    loadChildren: () => import('./pages/newaddress/newaddress.module').then( m => m.NewaddressPageModule)
  },
  {
    path: 'changepassword/:id',
    loadChildren: () => import('./pages/changepassword/changepassword.module').then( m => m.ChangepasswordPageModule)
  },
  {
    path: 'wishprice',
    loadChildren: () => import('./pages/wishprice/wishprice.module').then( m => m.WishpricePageModule)
  },
  {
    path: 'withdrawl',
    loadChildren: () => import('./pages/withdrawl/withdrawl.module').then( m => m.WithdrawlPageModule)
  },
  {
    path: 'addbank',
    loadChildren: () => import('./pages/addbank/addbank.module').then( m => m.AddbankPageModule)
  },
  {
    path: 'updatebank',
    loadChildren: () => import('./pages/updatebank/updatebank.module').then( m => m.UpdatebankPageModule)
  },
  {
    path: 'affiliate',
    loadChildren: () => import('./pages/affiliate/affiliate.module').then( m => m.AffiliatePageModule)
  },
  {
    path: 'fproducts/:id',
    loadChildren: () => import('./pages/fproducts/fproducts.module').then( m => m.FproductsPageModule)
  },
  {
    path: 'dealset',
    loadChildren: () => import('./pages/dealset/dealset.module').then( m => m.DealsetPageModule)
  },
  {
    path: 'linkedp',
    loadChildren: () => import('./linkedp/linkedp.module').then( m => m.LinkedpPageModule)
  },
  {
    path: 'pfullimg',
    loadChildren: () => import('./pages/pfullimg/pfullimg.module').then( m => m.PfullimgPageModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('./pages/brands/brands.module').then( m => m.BrandsPageModule)
  },
  {
    path: 'searchmap',
    loadChildren: () => import('./pages/searchmap/searchmap.module').then( m => m.SearchmapPageModule)
  },
  {
    path: 'popularprds',
    loadChildren: () => import('./pages/popularprds/popularprds.module').then( m => m.PopularprdsPageModule)
  },
  {
    path: 'storemap',
    loadChildren: () => import('./pages/storemap/storemap.module').then( m => m.StoremapPageModule)
  },
  {
    path: 'orderdetails',
    loadChildren: () => import('./pages/orderdetails/orderdetails.module').then( m => m.OrderdetailsPageModule)
  },
  {
    path: 'shiporders',
    loadChildren: () => import('./pages/shiporders/shiporders.module').then( m => m.ShipordersPageModule)
  },
  {
    path: 'shipordstatus',
    loadChildren: () => import('./pages/shipordstatus/shipordstatus.module').then( m => m.ShipordstatusPageModule)
  },
  {
    path: 'manageproducts',
    loadChildren: () => import('./pages/manageproducts/manageproducts.module').then( m => m.ManageproductsPageModule)
  },
  {
    path: 'productinventory',
    loadChildren: () => import('./pages/productinventory/productinventory.module').then( m => m.ProductinventoryPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./pages/forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'selflocs',
    loadChildren: () => import('./pages/selflocs/selflocs.module').then( m => m.SelflocsPageModule)
  },
  {
    path: 'newpass/:numb',
    loadChildren: () => import('./pages/newpass/newpass.module').then( m => m.NewpassPageModule)
  },
  {
    path: 'catproducts/:id/:name',
    loadChildren: () => import('./pages/catproducts/catproducts.module').then( m => m.CatproductsPageModule)
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'orders/:id',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'wallet/:id',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'favourite/:id',
    loadChildren: () => import('./pages/favourite/favourite.module').then( m => m.FavouritePageModule)
  },
  {
    path: 'home',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'demorders/:id',
    loadChildren: () => import('./pages/demorders/demorders.module').then( m => m.DemordersPageModule)
  },
  {
    path: 'serviceorders/:id',
    loadChildren: () => import('./pages/serviceorders/serviceorders.module').then( m => m.ServiceordersPageModule)
  },
  {
    path: 'amzprds/:id/:name',
    loadChildren: () => import('./pages/amzprds/amzprds.module').then( m => m.AmzprdsPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
