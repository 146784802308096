import { Component, OnInit } from '@angular/core';
import { NavParams, ToastController, PopoverController } from '@ionic/angular';
import { PostProvider } from 'src/app/pages/providers/post-provider';

@Component({
  selector: 'app-cartd',
  templateUrl: './cartd.component.html',
  styleUrls: ['./cartd.component.scss'],
})
export class CartdComponent implements OnInit {
  public product = this.navParmas.get('product');
  public productinfo = this.navParmas.get('productinfo');
  public itemcost = this.navParmas.get('itemcost');
  public itemmrp = this.navParmas.get('itemmrp');
  public shippingcharge = this.navParmas.get('shippingcharge');
  public user = this.navParmas.get('user');
  public dday = this.navParmas.get('dday');
  constructor(
    private navParmas: NavParams,
    private toastCtrl: ToastController,
    private accs: PostProvider,
    private popc: PopoverController
  ) { }

  ngOnInit() {}
goCart(){
  return new Promise(resolve=>{
    let body = {
     product: this.product,
     user: this.user,
     productinfo: this.productinfo,
     itemcost: this.itemcost,
     itemmrp: this.itemmrp,
     shippingcharge: this.shippingcharge
    }
    console.log(body);
    this.accs.postData(body, 'addtocart.php').subscribe((res:any)=>{
      if(res.success == true){
        this.popc.dismiss('addtocart');
        this.presentToast('Product Added to Cart');
        
      }else{
        this.presentToast('Product already in cart');
        console.log(res.msg);
      }
  });
  });
}
async presentToast(a){
  let toast = this.toastCtrl.create({
    message: a,
    duration: 3000,
    position: 'top'
  });

 (await toast).present();
} 
cancel(){
  this.popc.dismiss();
}
}
