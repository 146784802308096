import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  public dealname = this.navPar.get('dealname');
  constructor(
    private router: Router,
    private popv: PopoverController,
    private navPar: NavParams
  ) {
    console.log(this.dealname);
   }

  ngOnInit() {}
  goLtoh(ptyp){
    this.popv.dismiss({ prdpopu: ptyp}, 'filterType');
  }
}
