import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Platform, NavController, AlertController, ToastController } from '@ionic/angular';
import { Location } from '@angular/common';
import { Storage } from '@ionic/storage-angular';
import 'rxjs/add/operator/map'
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { environment } from 'src/environments/environment';
import { PostProvider } from './pages/providers/post-provider';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public user: any = [];
  public admin: any = [];
  membs: any;
  userid: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private navCtrl: NavController,
    public alertController: AlertController,
    private router: Router,
    private toastCtrl: ToastController,
    private http: HttpClient,
    private _location: Location,
    private alertCtrl: AlertController,
    private oneSignal: OneSignal,
    private accs: PostProvider
  ) {
    this.storage.create();
    this.initializeApp();
    
    setTimeout(async () => {
      await this.oneSignal.startInit(environment.onesignal.appId, environment.onesignal.googleProjectNumber);
      this.oneSignal.getIds().then((data) => {
        localStorage.setItem('fcm', data.userId); 
        console.log(data.userId);
        const user = localStorage.getItem('spartan');
        if(user && user !== null && user !== 'null'){
          return new Promise(resolve=>{
            let body = {
              id: user,
              fcm: data.userId
            }
            this.accs.postData(body, 'updatefcm.php').subscribe((res:any)=>{
              if(res.success == true){
                console.log('ouser', true);
              }else{
                console.log('ouser', false);
              }
          });
          });
        }       
      });
      await this.oneSignal.endInit();
    }, 1000);
    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/tabs/home')) {

        // Show Exit Alert!
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      }else if (this._location.isCurrentPathEqualTo('/login')) {

        // Show Exit Alert!
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      } else {

        // Navigate to back page
        console.log('Navigate to back page');
        this._location.back();

      }

    });
    this.platform.backButton.subscribeWithPriority(5, () => {
      console.log('Handler called to force close!');
      this.alertCtrl.getTop().then(r => {
        if (r) {
          navigator['app'].exitApp();
        }
      }).catch(e => {
        console.log(e);
      })
    });
  }
  showExitConfirm() {
    this.alertCtrl.create({
      header: 'Spartan Bussiness',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      mode:'ios',
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      
    });
    this.http.get('https://spartanbusiness.in/api/admindet.php').map(res => res).subscribe(data => {
      this.admin = data;
      });
  this.storage.get('storage_session').then((res) => {
    if(res == null){
      this.navCtrl.navigateRoot(['/login']);
    }else{
     this.navCtrl.navigateRoot(['/home']);
     this.user = res;
     for(let u of this.user){
       this.userid = u.id;
       const data ={
         id: u.id
       }
       this.http.post('https://spartanbusiness.in/api/getuser.php', JSON.stringify(data)).subscribe((data)=>{
         this.membs= data;
         console.log(this.membs);
       });
     }
     //console.log(this.user);
   }
  });
  }
  logOut(){
    this.storage.clear().then(async ()=>{
      this.router.navigate(['/login']);
      const toast = await this.toastCtrl.create({
          message: 'logout succesful',
          duration: 3000
        });
      toast.present();
    });
  }
  goWish(id){
    this.router.navigate(['/wishlist/'+id]);
  }
  goWallet(id){
    this.router.navigate(['/tabs/wallet/'+id]);
  }
  goFav(id){
    this.router.navigate(['/tabs/favourite/'+id]);
  }
  goManager(cat,brand){
    const param: NavigationExtras = {
      queryParams: {
        cat: cat,
        brand: brand
      }
    };
    this.router.navigate(['manageproducts'], param);
  }
}
