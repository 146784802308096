import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { LocproductsPage } from 'src/app/pages/locproducts/locproducts.page';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-filtover',
  templateUrl: './filtover.component.html',
  styleUrls: ['./filtover.component.scss'],
})
export class FiltoverComponent implements OnInit {
  public mpD:any=[];
  public cats:any=[];
  public brands:any=[];
  filters = {
    "category" : "cat",
    "pincode" : "pinc",
    "brand" : "bnd"
  }
  constructor(
    
    private http: HttpClient,
    private router: Router,
    private popv: PopoverController,
    private modalCtrl: ModalController
    
  ) { }

  ngOnInit() {
    this.mapdata();
  }
  mapdata(){
    this.http.get('https://spartanbusiness.in/api/loc_address.php').pipe(map(res => res)).subscribe(data => {
      this.mpD = data;
      });
     // this.displayGoogleMap();
     // this.getMarkers();

     this.http.get('https://spartanbusiness.in/api/category.php')
    .pipe(map(res => res)).subscribe(data => {
      this.cats = data;
    });

    this.http.get('https://spartanbusiness.in/api/brands.php').pipe(map(res => res)).subscribe(data => {
    this.brands = data;
    });
  }
  goCat(){
    this.router.navigate(['categories']);
    this.popv.dismiss();
  }
  async gopinloc(eve){
    const modal = await this.modalCtrl.create({
      component: LocproductsPage,
      componentProps: {
        'lid': eve.detail.value
      }
    });
    await modal.present();
    this.popv.dismiss();
  }
  goBrnds(){
    this.router.navigate(['brands']);
    this.popv.dismiss();
  }
  goBack(){
    this.popv.dismiss();
  }
  reset(){
    this.filters = {
      "category" : "cat",
      "pincode" : "pinc",
      "brand" : "bnd"
    }
  }
  categoryChange(ev){
    console.log(ev.detail.value);
    const cats = ev.detail.value;
    this.filters.category = cats;
  }
  pincodeChange(ev){
    console.log(ev.detail.value);
    const pins = ev.detail.value;
    this.filters.pincode = pins;
  }
  brandChange(ev){
    console.log(ev.detail.value);
    const bands = ev.detail.value;
    this.filters.brand = bands;
  }
  applyFilter(){
    console.log(this.filters);
  }
}
