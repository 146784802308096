import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userid: string;
  public users:any=[];
  public cats:any=[];
  public admin:any=[];
  usertype: any;
  phone: any;
  sphone: any;
  semail: any;
  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.getCat();
    const user=localStorage.getItem('user');
    this.userid = localStorage.getItem('user');
    if(user && user !== null && user !== 'null'){
      const data={
        id:user
      }
      this.http.post('https://spartanbusiness.in/api/userdetails.php', JSON.stringify(data)).subscribe((data)=>{
      this.users = data;
      for(let u of this.users){
        this.usertype=u.type;
      }
      });
    }
   }

  ngOnInit() {
    this.getCat();
  }
  getCat(){
    this.http.get('https://spartanbusiness.in/api/category.php').map(res => res).subscribe(data => {
    this.cats = data;
    });
    this.http.get('https://spartanbusiness.in/api/admin.php').map(res => res).subscribe(data => {
    this.admin = data;
    for(let a of this.admin){
      this.phone= a.phone;
      this.sphone=a.support_phones;
      this.semail=a.support_email;
    }
    });
    }
    gopD(name){
      this.router.navigate(['/deals/'+name]);
      }
      prdDtl(id,name){
        this.router.navigate(['/productdetails/'+id+'/'+name]);
      }
      location(){
        this.router.navigate(['/map']);
      }
      goCart(id){
        this.router.navigate(['/cart/'+id]);
      }
      goSearch(){
        this.router.navigate(['search']);
      }
      goHome(){
        this.router.navigate(['home']);
      }
      goWish(id){
        this.router.navigate(['/wishlist/'+id]);
      }
      goProfile(id){
        this.router.navigate(['/profile/'+id]);
      }
}
